import { Inject, Pipe, PipeTransform } from '@angular/core';
import type { NamespaceFile } from '../enums/namespace';
import { TranslateService } from '../services/translate.service';

@Pipe({
    name: 'translate',
    standalone: true,
})
export class TranslatePipe implements PipeTransform {
    constructor(@Inject(TranslateService) private translate: TranslateService) {
    }

    async transform(key?: string | null, ns: NamespaceFile = 'general', options?: Record<string, any>): Promise<string> {
        if (!(typeof key === 'string')) {
            return Promise.resolve('');
        }
        return await this.translate.t(key, ns, options);
    }
}
